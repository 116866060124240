import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on software installation.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Allowing employees to install software on company computing devices
opens the organization up to unnecessary exposure. Conflicting file
versions or DLLs which can prevent programs from running, the
introduction of malware from infected installation software, unlicensed
software which could be discovered during audit, and programs which can
be used to hack the organization’s network are examples of the problems
that can be introduced when employees install software on company
equipment.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this policy is to outline the requirements around
installation software on Data Migrators-owned computing devices. To
minimize the risk of loss of program functionality, the exposure of
sensitive information contained within Data Migrators’ computing
network, the risk of introducing malware, and the legal exposure of
running unlicensed software.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to all Data Migrators employees, contractors,
vendors and agents with a Data Migrators-owned mobile devices. This
policy covers all computers, servers, smartphones, tablets and other
computing devices operating within Data Migrators.`}</p>
    <h2>{`Policy`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Employees may not install software on Data Migrators’ computing
devices operated within the Data Migrators network.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Software requests must first be approved by the requester’s manager
and then be made to the Information Technology department or Help
Desk in writing or via email.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Software must be selected from an approved software list, maintained
by the Information Technology department, unless no selection on the
list meets the requester’s need.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The Information Technology Department will obtain and track the
licenses, test new software for conflict and compatibility, and
perform the installation.`}</p>
      </li>
    </ul>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, periodic walk-thrus, video
monitoring, business tool reports, internal and external audits, and
feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <p>{`None.`}</p>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`None.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      